import React, { useState } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col } from "reactstrap"
import AHDCIcon from "../components/img/icons/ahdc-icons"
import PropertyCarousel from "../components/carousel/property-carousel"
import BeforeAfterCarousel from "../components/carousel/beforeafter-carousel"
import BarExpand from "../components/img/bar-expand"
import PortfolioPagination from "../components/portfolio/pagination"

export const query = graphql`
  query($id: ID!) {
    wpgraphql {
      cptProperty(id: $id) {
        id
        title
        content
        acf_page_meta {
          metadescription
          metatitle
        }
        acf_properties {
          carouselImg {
            image {
              altText
              sourceUrl
              imageFile {
                childImageSharp {
                  fluid(maxWidth: 1900, quality: 90) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            preferredObjectFit
          }
          beforeAndAfter {
            beforeImage {
              altText
              sourceUrl
              imageFile {
                childImageSharp {
                  fluid {
                    src
                    srcSet
                    aspectRatio
                    sizes
                    base64
                  }
                }
              }
            }
            afterImage {
              altText
              sourceUrl
              imageFile {
                childImageSharp {
                  fluid {
                    src
                    srcSet
                    aspectRatio
                    sizes
                    base64
                  }
                }
              }
            }
          }

          communityImpact
          environmentalImpact
          economicImpact
          details {
            projectType
            units
            year
            irr
          }
          address {
            city
            state
          }
          websiteLink
          websiteLinkButton
          secondaryWebsiteLink
          secondaryWebsiteLinkButton
        }
      }
    }
  }
`
const PageTemplate = ({ data, location }) => {
  const property = data.wpgraphql.cptProperty
  const [count, setCount] = useState(0)

  return (
    <Layout navlocation={`/portfolio/`}>
      <SEO
        title={property.acf_page_meta.metatitle}
        description={property.acf_page_meta.metadescription}
      />
      <Container fluid={true} className="container__page">
        <Row>
          <Col className="px-0">
            {property.acf_properties.carouselImg != null && (
              <PropertyCarousel items={property.acf_properties.carouselImg} />
            )}
          </Col>
        </Row>

        <Row className="portfolio__card portfolio__template_carouseldetails pb-5">
          <Col
            md={{ offset: 1, size: 11 }}
            lg={{ offset: 2, size: 10 }}
            className="px-md-0 py-2 py-sm-3 py-md-5"
          >
            <h1
              className="text-secondary"
              dangerouslySetInnerHTML={{ __html: property.title }}
            />
          </Col>

          <Col
            md={{ offset: 1, size: 6 }}
            lg={{ offset: 2, size: 5 }}
            className="pb-3"
          >
            <div dangerouslySetInnerHTML={{ __html: property.content }} />
            <h3 className="text-primary">
              <AHDCIcon name={`communityblg`} color="#17ac52" /> Community
              Impact
            </h3>
            <p
              dangerouslySetInnerHTML={{
                __html: property.acf_properties.communityImpact,
              }}
              className="pb-3"
            />
            <h3 className="text-primary">
              <AHDCIcon name={`environmental`} color="#17ac52" /> Environmental
              Impact
            </h3>
            <p
              dangerouslySetInnerHTML={{
                __html: property.acf_properties.environmentalImpact,
              }}
              className="pb-3"
            />
            <h3 className="text-primary">
              <AHDCIcon name={`econsuccess`} color="#17ac52" /> Economic Impact
            </h3>
            <p
              dangerouslySetInnerHTML={{
                __html: property.acf_properties.economicImpact,
              }}
            />
          </Col>

          <Col
            md={{ size: 5 }}
            lg={{ offset: 1, size: 4 }}
            className="px-0 portfolio__project_details"
          >
            <div className="portfolio__project_details-card bg-dark text-white p-5">
              <h6>Project Type</h6>
              <p>{property.acf_properties.details.projectType}</p>
              <h6>Units</h6>
              <p>{property.acf_properties.details.units}</p>
              <h6>Location</h6>
              <p>
                {property.acf_properties.address.city},{" "}
                {property.acf_properties.address.state}
              </p>
              <h6>Year</h6>
              <p>{property.acf_properties.details.year}</p>
              {property.acf_properties.details.irr && <h6>IRR</h6>}
              {property.acf_properties.details.irr && (
                <p>{property.acf_properties.details.irr}</p>
              )}
              {property.acf_properties.websiteLink != null && (
                <a
                  className="btn btn-outline-info mr-1"
                  href={`${property.acf_properties.websiteLink}`}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {property.acf_properties.websiteLinkButton}
                </a>
              )}
              {property.acf_properties.secondaryWebsiteLink != null && (
                <a
                  className="btn btn-outline-info mr-1"
                  href={`${property.acf_properties.secondaryWebsiteLink}`}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {property.acf_properties.secondaryWebsiteLinkButton}
                </a>
              )}
            </div>
          </Col>
        </Row>

        {property.acf_properties.beforeAndAfter != null && (
          <Row>
            <Col md={{ size: 12 }} className="pt-2 portfolio_template__card">
              <BarExpand
                width="10"
                text="Before and After"
                class="text-secondary"
                spanclass="service-header"
              />
            </Col>
            <Col md={{ size: 8, offset: 2 }} className="pt-3 pb-5">
              <section className="section__investment-slider">
                <BeforeAfterCarousel
                  current={count}
                  items={property.acf_properties.beforeAndAfter}
                />
                <div className="carousel__thumbnails">
                  {property.acf_properties.beforeAndAfter.map((thumb, i) => (
                    <button
                      onClick={() => setCount(i)}
                      className="carousel__thumbnail"
                    >
                      <img
                        className="w-100"
                        alt={`${thumb.afterImage.altText} thumbnail`}
                        src={
                          thumb.afterImage.imageFile.childImageSharp.fluid.src
                        }
                      />
                    </button>
                  ))}
                </div>
              </section>
            </Col>
          </Row>
        )}
        <PortfolioPagination propid={property.id} />
      </Container>
    </Layout>
  )
}

export default PageTemplate
